import React from 'react';

export default function Home() {
  return (
 <>
 <html>
     <body>
     <main>
		<h1 class="intro">Hello, I'm Seetharam!</h1>
		<h3 class="tagline">SRE, Cloud Architect, Software Engineer and beyond..</h3>
		<p>I live in McLean, VA. I have been in tech since 2007, architecting cloud solutions and leading teams to build products. I currently work as a Senior Software Engineering Manager with Capital One. Prior to this, I have worked for larger enterprises such as Apple, 3M, Pfizer.
		<br></br><br></br>
		I am a versatlie person, who is passionate about learning new technologies every day. </p>
	</main>
     </body>
 </html>
 </>
  )
}
