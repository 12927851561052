import React from 'react';

export default function Home() {
  return (
 <>
 <html>
     <body>
     <main>
		<h4>Contact Me</h4>
		<h5>
		LinkedIn: <a href='https://www.linkedin.com/in/seetharaman-rajendran'> linkedin.com/in/seetharaman-rajendran</a><br></br>
		GitHub:   <a href='https://github.com/seetharamanr86'> github.com/seetharamanr86</a><br></br>
		Twitter: <a href='https://twitter.com/R_Seetharaman'> twitter.com/R_Seetharaman</a><br></br>
		Email: <a href='mailto:seetharaman.rajendran.86@gmail.com'> seetharaman.rajendran.86@gmail.com</a>
		</h5>
	</main>
     </body>
 </html>
 </>
  )
}
