import React from 'react';
import Header from './Header';
import Footer from './Footer';
import "./App.css";
import sr_photo from '../images/sr_photo.jpg';
import aws_solutions from '../images/aws-solutions.png';
import aws_dev from '../images/aws-dev.png';
import aws_sysops from '../images/aws-sysops.png';

function App(){
  return (
    <>
    <Header/>
    <img src={sr_photo} alt="sr_photo" width="100" height="100"/><br></br>
    <img src={aws_solutions} alt="aws_solutions" width="100" height="100"/>
    <img src={aws_dev} alt="aws_dev" width="100" height="100"/>
    <img src={aws_sysops} alt="aws_sysops" width="100" height="100"/>
    <Footer/>
    </>
  )
}

export default App;